
<template>
  <div class="head-img">
    <!--logo-->
    <img src="@/assets/home/logo.png" class="head-img-logo" />
    <div class="head-img-exit">
        <span class="el-dropdown-link">
          您好，{{userData.accountNumber}}
        </span>
      <div class="exit" @click="exitFun">
        <img class="hie-img" src="@/assets/home/exit.png" /> 退出
      </div>
    </div>
  </div>
</template>

<script>
//引入get
import { get } from "../../utils/request";
import $ from "jquery";

export default {
  data() {
    return {
      userData:{},
    };
  },
  created(){
    // 从 localStorage 中读取字符串
    const userString = localStorage.getItem("user");

// 使用 JSON.parse() 将字符串转换回对象
this.userData = JSON.parse(userString);
  },
  methods: {
    handleCommand(command) {
      if ("peopleFun") {
        this.$router.push({
          name: "PeopleInfo",
        });
        if ("passwordFun") {
          this.$router.push({
            name: "PeopleInfo",
          });
        }
        this.$message("click on item " + command);
      }
    },
    exitFun() {
      localStorage.clear();
      this.$router.push({
        name: "Home",
      });
      
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  color: #fff;
}
.exit{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(100vw*16/1920);
}
.exit:hover {
  cursor: pointer;
}
</style>

